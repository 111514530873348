import React, { useEffect, useState } from "react";
import UploadCsv from "./UploadCsv";
import UploadTable from "./components/UploadTable";
import { files } from "./data";
import { useFetchUploadHistoryMutation } from "../../../features/organisation/organisationApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import moment from "moment";

const CareplanUpload = () => {
  const user = useSelector(selectUser);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const s3Path = "https://caresms.s3.amazonaws.com/";

  const [fetchUploadHistory] = useFetchUploadHistoryMutation();

  const getUploadHistory = async () => {
    try {
      const { data } = await fetchUploadHistory(user.organisation.id).unwrap();

      setData(data);
      setRefresh(false);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  useEffect(() => {
    getUploadHistory();
  }, [refresh]);
  return (
    <div className="relative flex flex-col w-full">
      <div className="flex flex-col p-6 md:p-16 overflow-auto gap-8">
        {!user?.organisation?.data?.isEnterprise && (
          <div className="flex flex-col items-start justify-start gap-0">
            <p className="m-0 text-2xl font-bold">Upload Scheduling Data</p>
            <p className="m-0 text-sm text-primarytextgrey">
              Upload a new scheduling data file for CareSMS to create daily
              Provider schedules
            </p>
          </div>
        )}
        {!user?.organisation?.data?.isEnterprise && (
          <UploadCsv onBackCLick={() => {}} setRefresh={setRefresh} />
        )}
        <UploadTable
          data={data.map((row) => [
            row.filename,
            moment(row.createdAt).format("DD MMMM YYYY"),
            `${row.user?.back_office_staff?.firstname || ""} ${
              row.user?.back_office_staff?.lastname || ""
            }`,
            row.patients,
            row.providers,
            `${s3Path}${row.filepath}`,
          ])}
        />
      </div>
    </div>
  );
};

export default CareplanUpload;
