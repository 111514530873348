import { CalendarIcon } from "@mui/x-date-pickers-pro";
import { Icons } from "../../assets";
import FileSvg from "../../assets/svgs/FileSvg";

const menuOptions = [
  {
    id: 1,
    name: "Command Center",
    path: "/",
    icon: Icons.Communities_Icon1,
    hasSubsections: true,
    access: ["*"],
    subSections: [
      {
        id: 5,
        name: "Home",
        path: "/home",
        access: ["*"],
      },
      {
        id: 6,
        name: "Longitudinal Schedules",
        path: "/longitudinal-schedules",
        access: ["*"],
      },
      {
        id: 6,
        name: "Patient Scheduling Wizard",
        path: "/scheduling-wizard",
        access: ["*"],
      },
    ],
  },
  {
    id: 2,
    name: "Care Management",
    path: "/care-management",
    icon: Icons.Stats_Icon,
    hasSubsections: true,
    access: ["1"],
    subSections: [
      {
        id: 6,
        name: "Providers",
        path: "/care-management/providers",
        access: ["1"],
      },
      {
        id: 7,
        name: "Patients",
        path: "/care-management/patients",
        access: ["1"],
      },
      {
        id: 8,
        name: "Communities",
        path: "/care-management/communities",
        access: ["1"],
      },
      {
        id: 9,
        name: "Caretags",
        path: "/care-management/caretags",
        access: ["1"],
      },
      {
        id: 10,
        name: "Zones",
        path: "/care-management/zones",
        access: ["1"],
      },
    ],
  },
  {
    id: 3,
    name: "Messenger",
    path: "/chat",
    icon: Icons.Chat_Icon,
    access: ["1"],
  },
  {
    id: 21,
    name: "Scheduler",
    path: "/schedules",
    icon: CalendarIcon,
    noIcon: true,
    hasSubsections: true,
    access: ["1"],
    subSections: [
      {
        id: 0,
        name: "Data and Settings",
        access: ["1"],
        hasSubsections: true,
        subSections: [
          {
            id: 1,
            name: "Upload Scheduling Data",
            path: "/schedules/careplan-upload",
            access: ["1"],
          },
          {
            id: 2,
            name: "Provider Settings",
            path: "/schedules/configure/providers",
            access: ["1"],
          },
          {
            id: 4,
            name: "Visit Types",
            path: "/schedules/configure/visit-types",
            access: ["1"],
          },
          {
            id: 3,
            name: "Visit Rules",
            path: "/schedules/configure/visit-rules",
            access: ["1"],
          },
        ],
      },
      {
        id: 5,
        name: "Run Scheduler",
        path: "/schedules/run-scheduler",
        access: ["1"],
      },
    ],
  },
  {
    id: 22,
    name: "Reports",
    path: "/reports",
    icon: FileSvg,
    noIcon: true,
    hasSubsections: true,
    access: ["*"],
    subSections: [
      {
        id: 0,
        name: "Standard Reports",
        access: ["1"],
        hasSubsections: true,
        subSections: [
          {
            id: 1,
            name: "Patient Visit Report",
            path: "/reports/patient-visit-report",
            access: ["*"],
          },
          {
            id: 2,
            name: "Provider Activity Report",
            path: "/reports/provider-activity-report",
            access: ["*"],
          },
          {
            id: 3,
            name: "Revenue Report",
            path: "/reports/revenue-report",
            access: ["*"],
          },
          {
            id: 4,
            name: "Appointment Cancellation Report",
            path: "/reports/appointment-cancellation-report",
            access: ["*"],
          },
          {
            id: 5,
            name: "Overdue Patients Report",
            path: "/reports/overdue-patients-report",
            access: ["*"],
          },
          {
            id: 6,
            name: "New Patients Report",
            path: "/reports/new-patients-report",
            access: ["*"],
          },
        ],
      },
      {
        id: 7,
        name: "Report Builder",
        path: "/reports/report-builder",
        access: ["*"],
      },
    ],
  },
  {
    id: 23,
    name: "Analytics",
    path: "/analytics",
    icon: Icons.Stats_Icon,
    hasSubsections: true,
    access: ["*"],
    subSections: [
      {
        id: 1,
        name: "Pivot",
        path: "/analytics/pivot",
        access: ["*"],
      },
    ],
  },
];

export default menuOptions;
