import React, { useEffect } from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";
import CloseSvg from "../../../../assets/svgs/CloseSvg";
import {
  useCreateCaretagMutation,
  useEditCaretagMutation,
} from "../../../../features/caretag/caretagApiSlice";
import { useLSStore } from "../../../../context/ls-store";
import Loading from "../../../../components/Loading";
import { Tooltip } from "@mui/material";

const CaretagModal = ({
  type = "update",
  open,
  setOpen,
  data = null,
  setData,
  caretags,
  setCaretags,
}) => {
  const handleClose = () => {
    setName("");
    setDescription("");
    setStatus("ACTIVE");
    setOpen(false);
    setData(null);
  };

  const [edit, { isLoading: editLoading }] = useEditCaretagMutation();

  const [create, { isLoading: createLoading }] = useCreateCaretagMutation();

  const isLoading = editLoading || createLoading;

  const [name, setName] = React.useState(data !== null ? data.name : "");
  const [description, setDescription] = React.useState(
    data !== null ? data.description : ""
  );
  const [status, setStatus] = React.useState(
    data !== null ? data.status : "ACTIVE"
  );

  const setAlert = useLSStore((state) => state.setAlert);

  useEffect(() => {
    if (open && data !== null) {
      setName(data.name);
      setDescription(data.description);
      setStatus(data.status);
    }
  }, [open, data]);

  const updateCaretag = async () => {
    try {
      await edit({
        id: data.id,
        name,
        description,
        status,
      }).unwrap();

      const updatedCaretags = caretags.map((caretag) => {
        if (caretag.id === data.id) {
          return {
            ...caretag,
            name,
            description,
            status,
          };
        }
        return caretag;
      });

      setCaretags(updatedCaretags);

      setAlert({
        message: "Updated Caretag",
        type: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to update Caretag",
        type: "severity",
      });
    }
  };

  const createCaretag = async () => {
    try {
      const { data } = await create({
        name,
        description,
      }).unwrap();

      const updatedCaretags = [
        ...caretags,
        {
          id: data.id,
          name,
          description,
          status,
        },
      ];

      setCaretags(updatedCaretags);

      setAlert({
        message: "Created Caretag",
        type: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to create Caretag",
        type: "severity",
      });
    }
  };

  return (
    <ReusableModal open={open} hideHeader>
      <div className="flex flex-col items-center min-w-[480px]">
        <Loading loading={isLoading} />
        <div className="flex flex-row items-center justify-between w-full p-6">
          <p className="m-0 text-2xl font-bold text-primaryblack">
            {type === "update" ? "Update" : "Create"} Caretag
          </p>
          <Tooltip title="Close">
            <button
              onClick={handleClose}
              className="flex border-[2px] border-primarygrayborder items-center justify-center w-8 rounded-full aspect-square bg-primarylightgrey"
            >
              <CloseSvg color={"#828282"} />
            </button>
          </Tooltip>
        </div>
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-col w-full gap-4 p-6">
          <div className="flex flex-col w-full gap-2">
            <label htmlFor="Name" className="m-0 text-base font-semibold">
              Name
            </label>
            <input
              type="text"
              id="Name"
              name="Name"
              placeholder="Enter Name"
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-col w-full gap-2">
            <label
              htmlFor="Description"
              className="m-0 text-base font-semibold"
            >
              Description
            </label>
            <input
              type="text"
              id="tel"
              name="tel"
              placeholder="Enter Description"
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {type === "update" && (
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="Status" className="m-0 text-base font-semibold">
                Status
              </label>
              <select
                id="Status"
                name="Status"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
              </select>
            </div>
          )}
          <div className="flex flex-row items-center justify-between w-full gap-6">
            <button
              onClick={handleClose}
              className="w-1/2 p-2 font-semibold rounded-md bg-primarylightgrey border-[2px] text-primarytextgrey border-primarygrayborder"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                handleClose();
                if (type === "update") {
                  await updateCaretag();
                } else {
                  await createCaretag();
                }
              }}
              className="w-1/2 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            >
              {type === "update" ? "Yes, Update" : "Create"}
            </button>
          </div>
        </div>
      </div>
    </ReusableModal>
  );
};

export default CaretagModal;
