import React from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";

const CommunityAddModal = ({
  isOpen,
  edit,
  clearInput,
  onUpdate,
  onSave,
  setOpen,
  data,
  setData,
}) => {
  const handleChange = (e) => {
    const tmpData = { ...data };
    tmpData[e.target.name] = e.target.value;
    setData(tmpData);
  };

  return (
    <ReusableModal
      open={isOpen}
      title={edit ? "Edit community" : "Add new community"}
      onClose={() => {
        setOpen(false);
        clearInput();
      }}
    >
      <div className="flex flex-col items-center min-w-[480px]">
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-col gap-4 p-6 overflow-auto max-h-[calc(100vh-200px)]">
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="name" className="m-0 text-base font-semibold">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Community"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                value={data?.name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-1/2  gap-2">
              <label htmlFor="street" className="m-0 text-base font-semibold">
                Street
              </label>
              <input
                type="text"
                id="street"
                name="street"
                placeholder="Enter Street"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                value={data?.street}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="city" className="m-0 text-base font-semibold">
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                placeholder="Enter City"
                value={data?.city}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="state" className="m-0 text-base font-semibold">
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                placeholder="Enter State"
                value={data?.state}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col w-1/2 gap-2">
              <label htmlFor="zip" className="m-0 text-base font-semibold">
                Zip
              </label>
              <input
                type="text"
                id="zip"
                name="zip"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                placeholder="Zip code"
                value={data?.zip}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="w-full d-flex justify-center mb-2">
          <button
            disabled={
              data.name === "" ||
              data.street === "" ||
              data.city === "" ||
              data.state === "" ||
              data.zip === ""
            }
            className="w-75 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            onClick={async () => {
              if (edit) {
                await onUpdate();
              } else {
                await onSave();
              }
              // setOpen(false);
              // clearInput();
            }}
          >
            {edit ? "Save" : "Add"}
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default CommunityAddModal;
