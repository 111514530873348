import React, { useEffect } from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";
import CloseSvg from "../../../../assets/svgs/CloseSvg";
import { useLSStore } from "../../../../context/ls-store";
import {
  useCreateZoneMutation,
  useUpdateZoneMutation,
} from "../../../../features/zones/zonesApiSlice";

const ZoneModal = ({
  type = "update",
  open,
  setOpen,
  data = null,
  setData,
  zones,
  setZones,
}) => {
  const handleClose = () => {
    setName("");
    setZipCodes("");
    setOpen(false);
    setData(null);
  };

  const [edit] = useUpdateZoneMutation();

  const [create] = useCreateZoneMutation();

  const [name, setName] = React.useState(data !== null ? data.name : "");
  const [zipCodes, setZipCodes] = React.useState(
    data !== null ? data.zipCodes : ""
  );

  const setAlert = useLSStore((state) => state.setAlert);

  useEffect(() => {
    if (open && data !== null) {
      setName(data.name);
      setZipCodes(data.zipCodes);
    }
  }, [open, data]);

  const updateZone = async () => {
    try {
      await edit({
        id: data.id,
        name,
        zipCodes: zipCodes.split(",").map((zip) => zip.trim()),
      }).unwrap();

      const updatedZones = zones.map((zone) => {
        if (zone.id === data.id) {
          return {
            ...zone,
            name,
            zipCodes: zipCodes.split(",").map((zip) => zip.trim()),
          };
        }
        return zone;
      });

      setZones(updatedZones);

      setAlert({
        message: "Updated Zone",
        type: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to update Zone",
        type: "severity",
      });
    }
  };

  const createZone = async () => {
    try {
      const { data } = await create({
        name,
        zipCodes: zipCodes.split(",").map((zip) => zip.trim()),
      }).unwrap();

      const updatedZones = [
        ...zones,
        {
          id: data.id,
          name,
          zipCodes: data.zip_codes,
        },
      ];

      setZones(updatedZones);

      setAlert({
        message: "Created Zone",
        type: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to create Zone",
        type: "severity",
      });
    }
  };

  return (
    <ReusableModal open={open} hideHeader>
      <div className="flex flex-col items-center min-w-[480px]">
        <div className="flex flex-row items-center justify-between w-full p-6">
          <p className="m-0 text-2xl font-bold text-primaryblack">
            {type === "update" ? "Update" : "Create"} Zone
          </p>
          <button
            onClick={handleClose}
            className="flex border-[2px] border-primarygrayborder items-center justify-center w-8 rounded-full aspect-square bg-primarylightgrey"
          >
            <CloseSvg color={"#828282"} />
          </button>
        </div>
        <div className="h-[1px] w-full bg-primarygrayborder" />
        <div className="flex flex-col w-full gap-4 p-6">
          <div className="flex flex-col w-full gap-2">
            <label htmlFor="Name" className="m-0 text-base font-semibold">
              Name
            </label>
            <input
              type="text"
              id="Name"
              name="Name"
              placeholder="Enter Name"
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-col w-full gap-2">
            <label htmlFor="zipCodes" className="m-0 text-base font-semibold">
              Zip codes
            </label>
            <textarea
              id="zipCodes"
              name="zipCodes"
              placeholder="Enter zip codes separated by comma"
              rows={5}
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              value={zipCodes}
              onChange={(e) => setZipCodes(e.target.value)}
            />
          </div>
          <div className="flex flex-row items-center justify-between w-full gap-6">
            <button
              onClick={handleClose}
              className="w-1/2 p-2 font-semibold rounded-md bg-primarylightgrey border-[2px] text-primarytextgrey border-primarygrayborder"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                handleClose();
                if (type === "update") {
                  await updateZone();
                } else {
                  await createZone();
                }
              }}
              className="w-1/2 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            >
              {type === "update" ? "Yes, Update" : "Create"}
            </button>
          </div>
        </div>
      </div>
    </ReusableModal>
  );
};

export default ZoneModal;
