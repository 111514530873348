import React from "react";
import classNames from "classnames";
import { Search_Icon } from "../../../assets/images";
import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  DeleteOutline,
  PeopleSharp,
} from "@mui/icons-material";
import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFetchCommunityByIdMutation,
  useFetchCommunityPatientsMutation,
  useFetchCommunityProvidersMutation,
  useFetchCommunityStaffMutation,
} from "../../../features/community/communityApiSlice";
import ReusableModal from "../../../components/Modals/ReusableModal";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import {
  useCreateStaffUserMutation,
  useRemoveUserMutation,
} from "../../../features/user/userApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";

const CommunityInfo = () => {
  const [patients, setPatients] = React.useState([]);
  const [providers, setProviders] = React.useState([]);
  const [staff, setStaff] = React.useState([]);
  const [community, setCommunity] = React.useState(null);

  const [staffFirstName, setStaffFirstName] = React.useState("");
  const [staffLastName, setStaffLastName] = React.useState("");
  const [staffEmail, setStaffEmail] = React.useState("");
  const [staffPhone, setStaffPhone] = React.useState("");
  const [staffRole, setStaffRole] = React.useState(0);

  const [addStaffOpen, setAddStaffOpen] = React.useState(false);

  const [searchFilter, setSearchFilter] = React.useState("");

  const [tab, setTab] = React.useState("patients");

  const handleTabChange = (newTab) => {
    if (newTab !== tab) {
      setTab(newTab);
    }
  };

  const { id } = useParams();

  const navigate = useNavigate();

  const handlePatientClick = (patient) => {
    navigate(`/care-management/patients/${patient.id}`);
  };

  const handleProviderClick = (provider) => {
    navigate(`/care-management/providers/${provider.id}`);
  };

  const [getPatients] = useFetchCommunityPatientsMutation();
  const [getProviders] = useFetchCommunityProvidersMutation();
  const [getStaff] = useFetchCommunityStaffMutation();
  const [createStaffUser] = useCreateStaffUserMutation();
  const [removeUser] = useRemoveUserMutation();

  const user = useSelector(selectUser);

  const saveCommunityUser = async () => {
    const { data } = await createStaffUser({
      userData: {
        type: 2,
        email: staffEmail,
        phone: staffPhone,
      },
      staffData: {
        organisation_id: user.organisation.id,
        community_id: id,
        firstname: staffFirstName,
        lastname: staffLastName,
        role: staffRole,
      },
    }).unwrap();

    console.log(data);

    const staffList = [data, ...staff];
    setStaff(staffList);
  };

  const removeCommunityUser = async (userId) => {
    await removeUser({
      userId,
    }).unwrap();

    const staffList = staff.filter((staffMember) => staffMember.id !== userId);
    setStaff(staffList);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const [getCommunityById] = useFetchCommunityByIdMutation();

  const fetchPatients = async () => {
    try {
      const { data } = await getPatients(id).unwrap();

      setPatients(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  const fetchProviders = async () => {
    try {
      const { data } = await getProviders(id).unwrap();

      setProviders(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  const fetchStaff = async () => {
    try {
      const { data } = await getStaff(id).unwrap();

      setStaff(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  const fetchCommunity = async () => {
    try {
      const { data } = await getCommunityById(id).unwrap();

      setCommunity(data);
    } catch (error) {
      console.log(error.response);
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchCommunity();
      fetchPatients();
      fetchStaff();
      fetchProviders();
    }
  }, [id]);

  const Avatar = ({
    name,
    image,
    size = "w-10 h-10",
    textSize = "text-base",
    fontWeight = "font-normal",
  }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className={classNames(
                "object-cover w-10 h-10 rounded-full",
                size
              )}
            />
          </div>
        ) : (
          <p
            className={classNames(
              "flex items-center justify-center m-0 text-white rounded-full bg-primaryblue",
              size,
              textSize,
              fontWeight
            )}
          >
            {name
              .split(" ")
              .map((n) => n[0])
              .slice(0, 2)
              .join("")
              .toUpperCase()}
          </p>
        )}
      </>
    );
  };

  const formatPhoneNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    return match
      ? [match[1], match[2], match[3]].filter(Boolean).join("-")
      : "";
  };

  const clearInput = () => {
    setStaffFirstName("");
    setStaffLastName("");
    setStaffEmail("");
    setStaffPhone("");
    setStaffRole(0);
  };

  const tabName = {
    staff: "Staff",
    providers: "Providers",
    patients: "Patients",
  };

  const tabCount = {
    staff: staff.length,
    providers: providers.length,
    patients: patients.length,
  };

  return (
    <div className="flex flex-row items-start">
      <div className="w-3/12 py-4 pl-16 pr-8">
        <div className="w-full overflow-x-auto overflow-y-auto h-[calc(100vh-128px)] bg-white rounded-lg shadow border-1 border-primarygrayborder">
          <button className="p-3 text-primaryblue" onClick={handleBack}>
            Back
          </button>
          <div className="flex flex-col items-center justify-end gap-2 p-4 border-b border-b-primarygrayborder">
            <div className="relative">
              <Avatar
                name={community?.name ?? ""}
                image={null}
                size="w-20 h-20"
                textSize="text-3xl"
                fontWeight="font-semibold"
              />
              <div className="absolute right-0 w-4 h-4 rounded-full bottom-14 bg-primarygreen" />
            </div>
            {community && (
              <p className="m-0 text-lg font-bold">{community?.name}</p>
            )}
            <p className="m-0 text-sm text-primarytextgrey">
              {community?.address}
            </p>
            {community && community.email !== null && (
              <p className="m-0 text-sm text-primarytextgrey">
                {community.email}
              </p>
            )}
            {community && community.phone !== null && (
              <p className="m-0 text-sm text-primarytextgrey">
                {community.phone}
              </p>
            )}
          </div>
          <div className="flex flex-col h-auto gap-4 p-4">
            <button
              onClick={() => handleTabChange("patients")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "patients" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "patients" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "patients" && "text-primaryblue"
                )}
              >
                Patients
              </p>
            </button>
            <button
              onClick={() => handleTabChange("staff")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "staff" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "staff" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "staff" && "text-primaryblue"
                )}
              >
                Staff
              </p>
            </button>
            <button
              onClick={() => handleTabChange("providers")}
              className="flex items-center gap-4 cursor-pointer"
            >
              <div
                className={classNames(
                  "flex items-center justify-center w-10 h-10 rounded-md",
                  tab === "providers" && "bg-subtleblue"
                )}
              >
                <PeopleSharp
                  style={{
                    color: tab === "providers" ? "#3062D4" : "#000",
                    fontSize: "20px",
                  }}
                />
              </div>
              <p
                className={classNames(
                  "m-0 font-semibold",
                  tab === "providers" && "text-primaryblue"
                )}
              >
                Providers
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start w-9/12 py-4">
        <div className="flex flex-row items-center justify-between w-full pl-8 pr-16">
          <div className="flex flex-row items-center w-full gap-12">
            <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
              {tabName[tab]}{" "}
              <span className="ml-2 text-base font-bold text-primaryblue">
                {tabCount[tab]}
              </span>
            </h1>
            <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
              <div className="relative w-1/3 flex flex-row items-center gap-2 rounded-md border-[2px] border-primarygrayborder overflow-clip">
                <div className="pl-2">
                  <img
                    src={Search_Icon}
                    alt="search"
                    className="w-5 h-5 cursor-pointer search-icon opacity-80"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Search..."
                  className="w-full h-full p-2 text-base outline-none"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
          {tab === "staff" && (
            <button
              onClick={() => {
                setAddStaffOpen(true);
              }}
              className="px-4 py-2 shadow-sm rounded-md border-[2px] border-primarygrayborder whitespace-nowrap text-primaryblue"
            >
              Add New
            </button>
          )}
        </div>
        <div className="w-full pl-8 pr-16">
          <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
            {tab === "patients" ? (
              <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
                <thead>
                  <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                    <th className="px-4 py-3">
                      <p className="m-0">Name</p>
                    </th>
                    <th className="px-4 py-3">Last Visit Date</th>
                    <th className="px-4 py-3">DOB</th>
                    <th className="px-4 py-3">Gender</th>
                    <th className="px-4 py-3">EHR ID</th>
                    {/* <th className="px-4 py-3">Private / Group Home</th> */}
                    <th className="px-4 py-3">
                      <p className="m-0">Status</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patients
                    .filter((patient) =>
                      patient.name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    )
                    .map((patient, index) => (
                      <tr
                        className={classNames(
                          "text-left text-gray-900 h-24 whitespace-nowrap",
                          index !== patients.length - 1
                            ? "border-b"
                            : "border-none"
                        )}
                        key={index}
                      >
                        <td className="px-4 py-3 text-left">
                          <button
                            onClick={() => handlePatientClick(patient)}
                            className="flex flex-row items-center gap-4"
                          >
                            {/* <Avatar name={patient.name} image={null} /> */}
                            <p className="m-0 font-semibold underline">
                              {patient.name}
                            </p>
                          </button>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {moment(patient.lvd).format("MM/DD/YYYY")}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {moment(patient.dob).format("MM/DD/YYYY")}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {patient.sex === "F" ? "Female" : "Male"}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {patient.patientEhrId}
                        </td>
                        {/* <td className="px-4 py-3 text-left text-gray-700">
                          {patient.locationName === null
                            ? "Private Residence"
                            : patient.locationName}
                        </td> */}
                        <td className="px-4 py-3 text-left text-gray-700">
                          <div
                            className={classNames(
                              patient.status.toUpperCase() === "ACTIVE"
                                ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                                : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                              "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                            )}
                          >
                            <div
                              className={classNames(
                                "w-2 h-2 rounded-full",
                                patient.status.toUpperCase() === "ACTIVE"
                                  ? "bg-primarygreen"
                                  : "bg-primarytextgrey"
                              )}
                            />
                            {patient.status}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : tab === "providers" ? (
              <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
                <thead>
                  <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                    <th className="px-4 py-3">
                      <div className="flex flex-row items-center gap-2">
                        <p className="m-0">Name</p>
                        {/* <button
                          className="flex flex-col items-center gap-1"
                          onClick={() => {
                            if (sortNameOrder === "none") {
                              setSortNameOrder("asc");
                            }
                            if (sortNameOrder === "asc") {
                              setSortNameOrder("desc");
                            }
                            if (sortNameOrder === "desc") {
                              setSortNameOrder("none");
                            }
                          }}
                        >
                          {sortNameOrder === "asc" ? (
                            <ArrowDownwardTwoTone />
                          ) : sortNameOrder === "desc" ? (
                            <ArrowUpwardTwoTone />
                          ) : (
                            <div className="flex items-center h-4">
                              <div className="w-6 h-[2px] bg-white" />
                            </div>
                          )}
                        </button> */}
                      </div>
                    </th>
                    <th className="px-4 py-3">
                      <div className="flex flex-row items-center gap-2">
                        <p className="m-0">Speciality</p>
                        {/* <button
                          className="flex flex-col items-center gap-1"
                          onClick={() => {
                            if (sortSpecialityOrder === "none") {
                              setSortSpecialityOrder("asc");
                            }
                            if (sortSpecialityOrder === "asc") {
                              setSortSpecialityOrder("desc");
                            }
                            if (sortSpecialityOrder === "desc") {
                              setSortSpecialityOrder("none");
                            }
                          }}
                        >
                          {sortSpecialityOrder === "asc" ? (
                            <ArrowDownwardTwoTone />
                          ) : sortSpecialityOrder === "desc" ? (
                            <ArrowUpwardTwoTone />
                          ) : (
                            <div className="flex items-center h-4">
                              <div className="w-6 h-[2px] bg-white" />
                            </div>
                          )}
                        </button> */}
                      </div>
                    </th>
                    <th className="px-4 py-3">NPI ID</th>
                    <th className="px-4 py-3">Phone No.</th>
                    <th className="px-4 py-3">Email</th>
                    <th className="px-4 py-3">Address</th>
                    <th className="px-4 py-3">
                      <div className="flex flex-row items-center gap-2">
                        <p className="m-0">Status</p>
                        {/* <button
                          className="flex flex-col items-center gap-1"
                          // onClick={() => {
                          //   if (sortStatusOrder === "none") {
                          //     setSortStatusOrder("asc");
                          //   }
                          //   if (sortStatusOrder === "asc") {
                          //     setSortStatusOrder("desc");
                          //   }
                          //   if (sortStatusOrder === "desc") {
                          //     setSortStatusOrder("none");
                          //   }
                          // }}
                        >
                          {sortStatusOrder === "asc" ? (
                            <ArrowDownwardTwoTone />
                          ) : sortStatusOrder === "desc" ? (
                            <ArrowUpwardTwoTone />
                          ) : (
                            <div className="flex items-center h-4">
                              <div className="w-6 h-[2px] bg-white" />
                            </div>
                          )}
                        </button> */}
                      </div>
                    </th>
                    {/* <th className="px-4 py-3">Edit</th> */}
                    <th className="px-4 py-3">
                      <p className="m-0">Edit</p>
                    </th>
                    <th className="px-4 py-3">
                      <p className="m-0">Delete</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {providers
                    .filter((provider) =>
                      provider.name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    )
                    // .sort((a, b) => handleSortName(a, b))
                    // .sort((a, b) => handleSortSpeciality(a, b))
                    // .sort((a, b) => handleSortStatus(a, b))
                    .map((provider, index) => (
                      <tr
                        className={classNames(
                          "text-left text-gray-900 h-24 whitespace-nowrap",
                          index !== providers.length - 1
                            ? "border-b"
                            : "border-none"
                        )}
                        key={index}
                      >
                        <td className="px-4 py-3 text-left">
                          <div className="flex flex-row items-center gap-4">
                            <button
                              onClick={() => handleProviderClick(provider)}
                              className="flex flex-row items-center gap-4"
                            >
                              <Avatar
                                name={provider.name}
                                image={provider.profileImage}
                              />
                              <p className="m-0 font-semibold underline">
                                {provider.name}
                              </p>
                            </button>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {provider.speciality}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {provider.npi_id}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {provider.phone}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {provider.email}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {provider.address.join(", ")}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <div
                            className={classNames(
                              provider.status.toUpperCase() === "ACTIVE"
                                ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                                : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                              "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                            )}
                          >
                            <div
                              className={classNames(
                                "w-2 h-2 rounded-full",
                                provider.status.toUpperCase() === "ACTIVE"
                                  ? "bg-primarygreen"
                                  : "bg-primarytextgrey"
                              )}
                            />
                            {provider.status}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
                <thead>
                  <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                    <th className="px-4 py-3">Name</th>
                    <th className="px-4 py-3">Email</th>
                    <th className="px-4 py-3">Phone</th>
                    <th className="px-4 py-3">Role</th>
                    <th className="px-4 py-3">Status</th>
                    <th className="px-4 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {staff
                    .filter((staff) =>
                      staff.name
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase())
                    )
                    .map((staff, index) => (
                      <tr
                        className={classNames(
                          "text-left text-gray-900 h-24 whitespace-nowrap",
                          index !== staff.length - 1
                            ? "border-b"
                            : "border-none"
                        )}
                        key={index}
                      >
                        <td className="px-4 py-3 text-left">
                          <button className="flex flex-row items-center gap-4">
                            <Avatar name={staff.name} image={null} />
                            <p className="m-0 font-semibold underline">
                              {staff.name}
                            </p>
                          </button>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {staff.email}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {staff.phone}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          {staff.role === 1
                            ? "Nurse"
                            : staff.role === 2
                            ? "Admin"
                            : "Supervisor"}
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <div
                            className={classNames(
                              staff.status.toUpperCase() === "ACTIVE"
                                ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                                : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                              "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                            )}
                          >
                            <div
                              className={classNames(
                                "w-2 h-2 rounded-full",
                                staff.status.toUpperCase() === "ACTIVE"
                                  ? "bg-primarygreen"
                                  : "bg-primarytextgrey"
                              )}
                            />
                            {staff.status}
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-gray-700">
                          <button
                            onClick={() => removeCommunityUser(staff.id)}
                            className="flex items-center justify-center w-8 h-8 rounded-md aspect-square bg-primarylightgrey"
                          >
                            <DeleteOutline
                              style={{
                                fontSize: "20px",
                                color: "#EB5757",
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <ReusableModal open={addStaffOpen} hideHeader>
        <div className="flex flex-col items-center min-w-[480px]">
          <div className="flex flex-row items-center justify-between w-full p-6">
            <p className="m-0 text-2xl font-bold text-primaryblack">
              Send Invitaion
            </p>
            <button
              onClick={() => {
                setAddStaffOpen(false);
                clearInput();
              }}
              className="flex border-[2px] border-primarygrayborder items-center justify-center w-8 rounded-full aspect-square bg-primarylightgrey"
            >
              <CloseSvg color={"#828282"} />
            </button>
          </div>
          <div className="h-[1px] w-full bg-primarygrayborder" />
          <div className="flex flex-col gap-4 p-6">
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="email" className="m-0 text-base font-semibold">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder="John"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  value={staffFirstName}
                  onChange={(e) => setStaffFirstName(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="phone" className="m-0 text-base font-semibold">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder="Doe"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  value={staffLastName}
                  onChange={(e) => setStaffLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="email" className="m-0 text-base font-semibold">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Email"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                value={staffEmail}
                onChange={(e) => setStaffEmail(e.target.value)}
              />
            </div>
            <div className="flex flex-row w-full gap-4">
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="phone" className="m-0 text-base font-semibold">
                  Phone
                </label>
                <input
                  type="tel"
                  id="tel"
                  name="tel"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  placeholder="xxx-xxx-xxxx"
                  value={staffPhone}
                  onChange={(e) => {
                    if (e.target.value.length >= 13) {
                      return;
                    }
                    setStaffPhone(formatPhoneNumber(e.target.value));
                  }}
                />
              </div>
              <div className="flex flex-col w-1/2 gap-2">
                <label htmlFor="role" className="m-0 text-base font-semibold">
                  Role
                </label>
                <select
                  id="role"
                  name="role"
                  value={staffRole}
                  onChange={(e) => setStaffRole(e.target.value)}
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                >
                  <option value={0}>Select Role</option>
                  <option value={1}>Nurse</option>
                  <option value={2}>Admin</option>
                  <option value={3}>Supervisor</option>
                </select>
              </div>
            </div>
            <button
              disabled={
                staffFirstName === "" ||
                staffLastName === "" ||
                staffEmail === "" ||
                staffPhone === "" ||
                staffRole === 0
              }
              className="w-full disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
              onClick={async () => {
                await saveCommunityUser();
                setAddStaffOpen(false);
                clearInput();
              }}
            >
              Invite
            </button>
          </div>
        </div>
      </ReusableModal>
    </div>
  );
};

export default CommunityInfo;
