import React from "react";
import IconComponent from "../../../../../components/DataTable/Icon";

const AppointmentTypeTable = ({
  appointments,
  handleEditClick,
  isWelcomePage = false,
}) => {
  const parseNull = (value, replacement = "NA") => {
    return value === null || (Array.isArray(value) && value.length === 0)
      ? replacement
      : value;
  };

  return (
    <div
      className={
        isWelcomePage
          ? "max-h-96 overflow-auto"
          : "h-[calc(100vh-350px)] overflow-scroll no-scrollbar"
      }
    >
      <table className="w-full">
        <thead>
          <tr className="text-left bg-primarylightgrey">
            <th className="px-4 py-3">
              {!isWelcomePage ? "Visit Type" : "Type"}
            </th>
            <th className="px-4 py-3">EHR</th>
            <th className="px-4 py-3">Rotation Schedule (Days)</th>
            <th className="px-4 py-3">Visit Duration (mins)</th>
            <th className="px-4 py-3">Bill Rate ($)</th>
            <th className="px-4 py-3">Early Visit Window (Days)</th>
            <th className="px-4 py-3"></th>
          </tr>
        </thead>
        {appointments.length > 0 ? (
          <tbody>
            {appointments.map((type, index) => (
              <tr className="text-left text-gray-900 border-bottom" key={index}>
                <td className="px-4 py-3">{type.appointmentName}</td>
                <td className="px-4 py-3">{type.ehrName}</td>
                <td className="px-4 py-3">
                  {type.rotation} {type.rotation > 1 ? "days" : "day"}
                </td>
                <td className="px-4 py-3">{parseNull(type.duration)}</td>
                <td className="px-4 py-3">{parseNull(type.rate)}</td>
                <td className="px-4 py-3">
                  {type.earlyVisitWindow === 0 || type.earlyVisitWindow == null
                    ? "NA"
                    : `${type.earlyVisitWindow} ${
                        type.earlyVisitWindow > 1 ? "days" : "day"
                      }`}
                </td>
                <td className="flex flex-row items-center gap-2 px-4 py-3">
                  <button
                    className="p-2 text-white bg-gray-200 rounded-md"
                    onClick={() => {
                      handleEditClick(type.id);
                    }}
                  >
                    <IconComponent
                      tooltip={"Edit appointment type"}
                      icon="Edit_Icon"
                      i={type.id}
                      key={type.id}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className="relative">
            <div
              className="absolute w-full py-2 my-3 d-flex align-Items-center justify-content-center"
              style={{ zIndex: 0 }}
            >
              <p className="text-xl font-bold text-primarygrayborder">
                {" "}
                No Records Found
              </p>
            </div>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default AppointmentTypeTable;
